@import 'styles/config';

.container {
  flex: 1;
  width: 100%;
  align-items: center;
  padding-bottom: rem(100);
}

.contentContainer {
  flex: 1;
  width: 100%;
  max-width: 500px;
  justify-content: center;
  @include phone-only {
    width: 95%;
  }

  & > svg {
    align-self: center;
  }

  .headerTitle {
    margin-top: rem(16);
    font-family: $heading-font-family;
    font-size: em(32);
    text-align: center;
  }
  .headerSubTitle {
    text-align: center;
  }

  .leanScoreBox {
    width: 100%;
    height: rem(200);
    background-color: $gray800;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    color: $white;
    margin-bottom: rem(16);
    margin-top: rem(32);

    & > svg {
      height: 26px;
      width: 21px;
      path {
        fill: $primaryGreenDark;
      }
    }
    .score {
      margin-top: rem(8);
      font-size: em(28);
      font-weight: 700;
    }
  }
  .contentTitle {
    font-family: $heading-font-family;
    font-size: em(24);
    text-transform: uppercase;
    margin-bottom: rem(16);
    margin-top: rem(16);
  }
  .doneBtn {
    margin-top: rem(60);
  }
}
