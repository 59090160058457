@import 'styles/config';

.recipeItem {
  max-width: 160px;

  &:hover {
    cursor: pointer;
  }

}

.recipePreviewImg {
  border-radius: 8px;
  height: 210px;
  object-fit: cover;

  @include phone-only {
    height: 380px;
  }
}

.recipePreviewDefault {
  border: 1px solid $gray100;
  border-radius: 8px;
  height: 210px;

  @include phone-only {
    height: 400px;
  }
}

.recipeTitle {
  margin-top: rem(16);
  font-size: em(17);
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.recipeDuration {
  margin-top: rem(8);
  color: $gray500;
  font-size: em(13);
  text-transform: uppercase;
  margin-bottom: rem(16);
}