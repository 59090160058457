@import 'styles/config';

.container {
  flex: 1;
  width: 100%;
  align-items: center;
  position: relative;
  padding-bottom: rem(100);
  @include lt-desktop {
    align-self: center;
    width: 95%;
  }
}

.contentContainer {
  max-width: 580px;
  width: 100%;
}

.header {
  align-items: center;
  & > p {
    font-family: $heading-font-family;
    font-size: em(32);
    margin-top: rem(4);
    text-transform: uppercase;
    margin-bottom: rem(16);
    text-align: center;
  }
  .banner {
    flex: 1;
    max-width: 415px;
    background: rgba(61, 163, 186, 0.05);
    border-radius: 16px;
    flex-direction: row;
    align-items: center;
    padding-top: rem(8);
    padding-left: rem(8);
    padding-right: rem(8);
    & > p {
      flex: 1;
      text-align: left;
      font-weight: 700;
      overflow-wrap: break-word;
      padding-left: rem(16);
      padding-right: rem(16);
    }
  }
}

.optionsContainer {
  background-color: $gray50;
  max-height: 125px;
  min-height: 125px;
  border-radius: 16px;
  margin: rem(32) 0;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
}

// horizontal padding
.emptyDiv {
  min-width: rem(24);
  height: 100%;
}

.mealTypeOption {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 rem(12);
  padding: rem(12) 0;
  min-width: rem(75);

  & > p {
    margin-top: rem(8);
    font-weight: 600;
  }
  .mealTypeIconContainer {
    width: 48px;
    height: 48px;
    padding: rem(8);
    border-radius: 50%;
    background-color: $primaryGreenLight;
    justify-content: center;
    align-items: center;
    & > img {
      max-width: 24px;
      object-fit: contain;
    }
    &:hover {
      cursor: pointer;
      border: 2px solid $primaryGreen;
    }
    &.activeContainer {
      border: 2px solid $primaryGreen;
    }
  }

  .hasRecipe {
    background-color: $primaryGreen;
    &:hover {
      cursor: pointer;
      border: 2px solid $primaryGreenDark;
    }
    &.activeContainer {
      border: 2px solid $primaryGreenDark;
    }
  }
}

.content {
  flex: 1;
}
.categoryRow {
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  .categoryItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    border: 1px solid $fullTransparent;
    padding: rem(4) rem(16);
    margin-right: rem(16);
    margin-bottom: rem(16);
    & > p {
      color: $gray600;
      font-weight: 600;
    }
  }
  .activeCategoryItem {
    background-color: $primaryGreenDark;
    border-color: $primaryGreenDark;

    & > p {
      color: $white;
    }
  }
}

.recipeItemsContainer {
  min-width: 370px;
  margin-top: rem(24);
  flex-direction: row;
  flex-wrap: wrap;

  & > div {
    margin-bottom: rem(24);
    margin-right: rem(24);
  }

  @include phone-only {
    min-width: auto;
    flex-direction: column;
    align-items: center;
    min-height: 450px;
    & > div {
      margin-bottom: rem(24);
      margin-right: 0;
      width: 100%;
      & > div {
        max-width: 420px;
      }
    }
  }
}

.addMealBtn {
  margin-top: auto;
  background-color: $primaryGreenLight;
  height: rem(36);
  max-width: rem(240);

  &.active {
    border: 2px solid $primaryGreenDark;
  }
}

.addMealLabel {
  color: $primaryGreenDark;
}

.actionBtn {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 0;

  max-width: 580px;

  @include lt-desktop {
    max-width: none;
  }
}
