@import 'styles/config';

.modal {
  position: relative;
  padding: rem(32);
  align-items: center;
  text-align: center;
  background-color: $white;
  border-radius: rem(16);

  .modalCloseBtn {
    width: auto;
    height: rem(24);
    position: absolute;
    top: rem(8);
    left: rem(8);
  }
}