@import 'styles/config';

.container {
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  background-color: $gray800;
  padding: rem(20);
  border-radius: 12px;

  .scoreBox {
    justify-content: center;
    align-items: center;
    text-align: center;
    & > p:first-of-type {
      color: $primaryGreenDark;
      font-weight: bold;
      font-size: em(17);
    }
    & > p:last-of-type {
      color: $gray100;
      font-size: em(11);
      font-weight: 400;
    }
  }

  .divider {
    height: 90%;
    width: 1px;
    background-color: $gray700;
  }
}
