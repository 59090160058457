@import 'styles/config';

.optionContainer {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  background: $white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px -1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  border: 1.5px solid rgba(0, 0, 0, 0);
  margin-bottom: rem(16);
  padding: rem(20) rem(16);

  &:hover {
    border: 1.5px solid $primaryGreen;
  }

  .iconContainer {
    width: rem(32);
    height: rem(32);
    border-radius: rem(16);
    background-color: $primaryGreenLight;
    justify-content: center;
    align-items: center;
    margin-right: rem(16);

    &>img {
      max-height: 16px;
      max-width: 16px;
    }

    &>svg {
      height: 16px;
      width: 16px;
    }
  }

  .optionContent {
    flex: 1;

    &>p:nth-child(1) {
      color: $black;
      font-weight: bold;
      font-size: em(17);
      margin-bottom: rem(2);
    }

    &>p:nth-child(2) {
      color: $darkGray;
      font-weight: bold;
      font-size: em(13);
    }
  }
}

.optionSelected {
  border: 1.5px solid rgba(61, 163, 186, 0.4);
}