@import 'styles/config';




.content {



  &__mobile {
    width: 100%;
    background-color: #fff;

    // display: none;

    @media only screen and (max-width: 985px) and (min-width: 600px) {
      display: block;
    }

    @include phone-only {
      display: block;
    }
  }
}

.sider {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding-top: 200px;
  align-items: center;

  &__container {
    width: 400px;
  }

  // @include phone-only {
  //   display: none;
  // }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 45px;

  @include phone-only {
    background: #fff;
  }

  &__title {
    font-family: Trim Poster;
    font-style: normal;
    font-weight: normal;

    line-height: 90%;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 1px;

    @include phone-only {
      color: #000000;
      text-align: center;
      width: 75%;
    }

    &_sale {
      font-weight: 400;
      font-size: 22px;
    }
  }

  &__sale {
    background: #ffffff;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
    padding: 15px;
    margin: 10px;

    @include phone-only {
      box-shadow: unset;
      padding: 7px;
      margin: unset;
    }

    h1 {
      font-family: 'Trim Poster';
      font-style: normal;
      font-weight: 400;
      font-size: 61px;
      line-height: 90%;
      /* or 55px */

      text-align: center;
      text-transform: uppercase;
    }
  }

  &__description {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */
    text-align: center;

    color: #ffffff;

    @include phone-only {
      text-align: center;
      color: #858585;
      font-size: 13px;
      width: 75%;
    }
  }
}

.carousel {
  width: 467px;
  margin-top: 18px;
  position: relative;

  @include phone-only {
    width: 100vw;
  }

  &__track {
    height: max-content;
    overflow-y: hidden;
  }

  &__dots {
    li {
      button {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;

        @include phone-only {
          opacity: 1 !important;
          background: #f2f2f2 !important;
        }
      }
    }
  }

  &__card {
    background: transparent;

    @include phone-only {
      padding-left: 25px;
      padding-right: 25px;
    }

    &_cover {
      width: 100%;
      height: 227px;
      border-radius: 10px 10px 0 0;
      overflow: hidden;

      @include phone-only {
        height: 166px;
        border-radius: 0;

        img {
          width: 100%;
        }
      }
    }

    &_details {
      margin-bottom: 60px;
      background-color: #fff;
      border-radius: 0 0 10px 10px;
      padding: 20px;
    }

    &_wrapper {
      box-shadow: 0px 4px 15px -3px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
  }

  &__controls {
    position: absolute;
    width: 33px;
    height: 33px;
    background: #f8fafc;
    border-radius: 50%;
    z-index: 1;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &_right {
      right: -18px;

      // justify-content: flex-end;
      @include phone-only {
        right: 12px;
      }
    }

    &_left {
      left: -18px;

      // justify-content: flex-start;
      @include phone-only {
        left: 12px;
      }
    }
  }
}

.whatYouGet {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    position: relative;
    padding-left: 1.5em;
    /* space to preserve indentation on wrap */
    margin-bottom: rem(16);
    font-size: em(15);
    line-height: 140%;
    font-weight: 400;
    color: #000;
    padding-left: em(30);

    @include phone-only {
      margin-bottom: rem(4);
    }

    &:before {
      content: '';
      /* placeholder for the SVG */
      position: absolute;
      left: 0;
      /* place the SVG at the start of the padding */
      width: 2em;
      height: 2em;
      background: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8.38737' cy='9.35807' r='8.38737' fill='%233DA3BA'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.4238 6.36206C12.6309 6.56433 12.6348 6.89622 12.4325 7.10335L7.3133 12.3455C7.21015 12.4511 7.06724 12.5083 6.91969 12.5031C6.77214 12.4979 6.63364 12.4307 6.53823 12.318L4.31852 9.69693C4.13142 9.476 4.15885 9.14522 4.37978 8.95812C4.60071 8.77102 4.93149 8.79844 5.11859 9.01938L6.96586 11.2007L11.6825 6.37085C11.8847 6.16372 12.2166 6.15978 12.4238 6.36206Z' fill='white'/%3E%3C/svg%3E%0A") no-repeat;
    }
  }
}

.accessFormSection {
  &__header {
    display: flex;

    &_step {
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 800;
      font-size: 15.44px;
      line-height: 140%;
      color: #000000;
    }

    &_prev_button {
      width: 25px;
      height: 25px;
      background: #f8fafc;
      border-radius: 50%;
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__title {
    font-family: Trim Poster;
    font-style: normal;
    font-weight: normal;
    line-height: 110%;
    /* identical to box height, or 80px */
    text-transform: uppercase;

    color: #000000;
  }

  &__subtitle {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    color: #858585;

    a {
      color: #3da3ba;
    }
  }
}

.formDescription {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  color: #000000;

  span {
    font-family: $sf-pro-display-bold-font-family;
    color: #000000;
  }
}

.selectPlan {
  width: 100%;
  padding: 25px;

  &__button {
    width: 100%;
    background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #be120b, #be120b),
      #c8130c;
    border: 2px solid #b5b5b5;
    box-sizing: border-box;
    border-radius: 20px;
    margin-top: 10px;
    display: flex;
    padding: 20px;
    flex-direction: column;

    h1,
    p {
      color: #000000;
    }

    &_selected {
      background: linear-gradient(0deg, #ff1a1a, #ff1a1a), linear-gradient(0deg, #be120b, #be120b),
        #c8130c;
      border: 2px solid #ff5f57;

      h1,
      p {
        color: #ffffff;
      }

      &_promo {
        background: #ffffff !important;

        p {
          color: #ff5f57 !important;
        }
      }
    }

    &_level {
      display: flex;
      width: 100%;
      justify-content: space-between;

      &_promo {
        padding: 2px 8px;
        background: #ff1a1a;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-family: SF Pro Display;
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 140%;
          color: #ffffff;
        }
      }

      &_circle {
        font-family: SF Pro Display;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #ffffff;
      }

      &_main_circle {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        h1 {
          font-family: $sf-pro-display-bold-font-family;
          font-style: normal;
          font-weight: 800;
          font-size: 42px;
          line-height: 100%;
          letter-spacing: -0.02em;
          text-transform: capitalize;
        }

        p {
          font-family: $sf-pro-display-bold-font-family;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 140%;
          text-align: left;
        }
      }

      &_price {
        padding-top: 14px;

        h1 {
          font-family: $sf-pro-display-bold-font-family;
          font-style: normal;
          font-weight: bold;
          font-size: 33px;
          line-height: 100%;
          letter-spacing: -0.02em;
          display: flex;
          justify-content: flex-end;

          span {
            font-family: $sf-pro-display-bold-font-family;
            font-style: normal;
            font-weight: 900;
            font-size: 13.037px;
            line-height: 100%;
            letter-spacing: -0.02em;
          }
        }

        p {
          font-family: SF Pro Display;
          font-style: normal;
          font-weight: 500;
          font-size: 12.7954px;
          line-height: 100%;
          letter-spacing: -0.02em;
          text-align: right;
        }

      }

      &_original_price {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: 500;
        font-size: 24.3813px;
        line-height: 100%;
        letter-spacing: -0.02em;
        text-decoration-line: line-through;
        color: #ffffff;
        display: flex;
        justify-content: flex-start;

        span {
          font-size: 9.63211px;
          line-height: 100%;
          letter-spacing: -0.02em;
        }
      }
    }
  }

  &__description {
    width: 100%;
    padding: 10px;
    background: rgba(61, 163, 186, 0.2);
    border-radius: 6px;
    margin-top: 15px;

    p {
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: #22879f;
    }
  }
}

.planFooter {
  padding-top: 20px;
  display: flex;
  align-items: center;

  label {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 140%;
    letter-spacing: -0.24px;
    color: #858585;

    a {
      color: #3da3ba;
    }
  }

  .currency {
    font-family: SF Pro Display;
    font-size: 15px;
    line-height: 140%;
    font-weight: 800;
    color: #303030;
    font-style: italic;

    span {
      color: #3da3ba;
      font-weight: bold;
    }
  }
}

.continue {
  background: #FF5050 !important;
  border-radius: 8px;
  border: 0;
  width: 100%;
  height: 47px;
  margin-top: 14px;

  span {
    font-family: $sf-pro-display-bold-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 140%;
  }

  &__preview {
    display: none;

    @include phone-only {
      display: block;
    }
  }

  &__form {
    background: #ff1a1a !important;
  }
}

.review {
  padding: 16px;

  display: flex;
  justify-content: space-between;
  background: linear-gradient(352.04deg, #e6fbff -5.56%, rgba(255, 255, 255, 0.88) 108.47%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px;
  margin-bottom: 60px;

  @include phone-only {
    flex-direction: column;
    align-items: center;
    margin-left: 25px;
    margin-right: 25px;
  }

  &__image {
    width: 188px;
    height: 166px;
    background-color: transparent;

    @include phone-only {
      width: 100%;
      height: auto;
    }

    img {
      object-fit: fit;

      @include phone-only {
        width: 100%;
        height: auto;
      }
    }
  }

  &__content {
    width: 70%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_stars {
      display: flex;

      @include phone-only {
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 13px;
      }

      svg {
        margin-right: 3px;
        width: 18.41px;
        height: 18.41px;
      }
    }

    &_text {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 140%;
      color: #171922;

      @include phone-only {
        text-align: center;
      }
    }

    &_footer {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 12.5946px;
      line-height: 140%;
      color: #22879f;

      @include phone-only {
        padding-top: 10px;
        text-align: center;
      }
    }
  }
}

.disclaimer {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 140%;
  /* or 15px */
  letter-spacing: 0.2px;



  // text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.45);
  text-align: center;
  margin-bottom: 50px;

  @include phone-only {
    margin-left: 30px;
    margin-right: 50px;
  }
}

.section__review {

  &_title {
    font-family: Trim Poster;
    font-style: normal;
    font-weight: normal;
    text-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);




  }
}

.header {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);

  @include phone-only {
    box-shadow: 0px 2.77228px 10.396px rgba(0, 0, 0, 0.05);
  }

  &__logo {
    width: 116px;
    height: 39px;
    border-radius: 0;

    @include phone-only {
      width: 75.8px;
      height: 25.49px;
    }
  }

  &__text {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #000000;
  }

  &__login {
    background: #3da3ba !important;
    border-color: #3da3ba !important;
    border-radius: 4.57426px;
    margin-left: 10px;
  }
}

.paymentDetails {
  &__title {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.24px;

    color: #858585;
  }

  &__price {
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    text-align: right;
    letter-spacing: -0.24px;

    /* Black500 */

    color: #171923;

    &_total {
      font-family: $sf-pro-display-bold-font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 140%;
      /* or 22px */

      text-align: right;
      letter-spacing: -0.24px;

      /* Black500 */

      color: #171923;
    }
  }
}