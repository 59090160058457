@import 'styles/config';

.container {
  flex: 1;
  align-items: center;
  padding-bottom: rem(100);
}

.contentContainer {
  max-width: 375px;
  width: 100%;
  @include phone-only {
    width: 95%;
  }

  .profileHeader {
    background-color: $gray800;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding: rem(24) rem(24);
  }

  .profileName {
    margin-top: rem(8);
    font-weight: bold;
    font-size: em(21);
    color: $white;
    text-align: center;
  }

  .contentTitle {
    font-family: $heading-font-family;
    font-size: em(24);
    text-transform: uppercase;
    margin-bottom: rem(16);
    margin-top: rem(16);
  }

  .settingsOptionsContainer {
    & > button {
      margin-bottom: rem(16);
    }
  }
}

.logoutBtn {
  margin-top: rem(40);
  max-width: rem(225);
}
