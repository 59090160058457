@import 'styles/config';

.headline {
  font-family: $heading-font-family;
  font-weight: normal;
  font-size: em(32);
  line-height: 100%;
  color: $black;
}

.subHeading {
  font-size: em(15);
  line-height: 140%;
  font-weight: 400;
  color: $gray600;
  margin-top: rem(8);
}

.inputsContainer {
  margin-top: rem(20);

  .input {
    margin-bottom: rem(16);
  }
}

.actionBtn {
  margin-top: auto;
}

.errorMessage {
  margin-bottom: rem(16);
  align-self: center;
}

.footer {
  margin-top: auto;
}
