@import 'styles/config';

.container {
  flex: 1;
  align-items: center;
  padding-bottom: rem(100);
  padding-top: rem(100);
}

.contentContainer {
  padding: rem(30);
  width: 100%;
  max-width: rem(400);
  min-height: rem(500);
  background: $white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), 0px -1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  @include phone-only {
    padding: rem(24);
    width: 95%;
  }

  .header {
    height: 200px;
    text-align: center;
    position: relative;
    justify-content: center;
    padding: rem(16);
    border-radius: 16px;

    &>img {
      height: 200px;
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      border-radius: 16px;
    }

    p {
      color: $white;
      z-index: 9;
    }
  }

  .input {
    margin-bottom: rem(16);
  }

  .topPadding {
    margin-top: rem(8);
  }

  .headline {
    font-family: $heading-font-family;
    font-weight: normal;
    font-size: em(32);
    line-height: 100%;
    color: $black;
  }

  .promoCode {
    text-align: center;
    font-size: em(16);
    font-weight: bold;
    margin-top: rem(8);
  }

  .subHeading {
    font-size: em(15);
    line-height: 140%;
    font-weight: 400;
    color: $gray600;
    margin-top: rem(8);
    margin-bottom: rem(8);
  }

  .optionsContainer {
    margin: rem(12) 0;
  }
}

.banner {
  max-width: 335px;
  margin: rem(16) 0;
  background: rgba(61, 163, 186, 0.05);
  border-radius: 16px;
  flex-direction: row;
  align-items: center;
  padding-top: rem(8);
  padding-left: rem(8);
  padding-right: rem(8);

  &>p {
    flex: 1;
    text-align: center;
    font-weight: 700;
    overflow-wrap: break-word;
  }
}

.subscriptionStatus {
  font-weight: 700;
  font-size: em(13);
  color: $gray600;
  margin-bottom: rem(8);

  span {
    text-transform: uppercase;
  }
}

.currentSubscription {
  font-weight: 700;
  color: $gray600;
  margin-bottom: rem(8);
}

.updatePlanBtn {
  margin-top: rem(16);
  background-color: $background-color;
  border: 1px solid $primaryGreenDark;

  &>p {
    color: $primaryGreenDark;
  }
}

.updatePaymentMethodBtn {
  margin-top: rem(16);
  background-color: $background-color;
  border: 1px solid $primaryGreenDark;

  &>p {
    color: $primaryGreenDark;
  }
}

.cancelSubscriptionBtn {
  margin-top: rem(16);
  background-color: $errorRed;
  border: 1px solid $errorRed;

  &>p {
    color: $background-color;
  }

  :disabled {
    background-color: $gray400;
    border: 1px solid $gray400;

    &>p {
      color: $gray600;
    }
  }
}

.logoutBtn {
  min-width: 250px;
  margin-bottom: rem(32);
  margin-top: rem(8);
  background-color: $background-color;

  &>p {
    color: $default-font-color;
  }
}

.loggedInAs {
  margin-top: rem(32);
  font-weight: bold;
  font-size: em(14);
}

.iosMessage {
  font-weight: 700;
  color: $gray600;
  font-size: em(15);
  margin-top: rem(24);
  text-align: start;
}