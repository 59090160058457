@import 'styles/config';

.container {
  flex: 1;
  align-items: center;
  padding-bottom: rem(100);
  padding-top: rem(100);
}

.contentContainer {
  padding: rem(30);
  width: 100%;
  max-width: rem(400);
  min-height: rem(500);
  background: $white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), 0px -1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  @include phone-only {
    padding: rem(24);
    width: 95%;
  }

  .headline {
    font-family: $heading-font-family;
    font-weight: normal;
    font-size: em(32);
    line-height: 100%;
    color: $black;
  }

  .subHeading {
    font-size: em(15);
    line-height: 140%;
    font-weight: 400;
    color: $gray600;
    margin-top: rem(8);
    margin-bottom: rem(8);
  }
}

.saveBtn {
  margin-top: rem(60);
}
