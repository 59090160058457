@import 'styles/config';

.container {
  flex: 1;
  align-items: center;
  padding-bottom: rem(100);
  padding-top: rem(30);
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  gap: 20px 120px;

}

.link {
  font-size: em(15);
  align-self: center;
  margin-top: rem(5);
  margin-bottom: rem(22);
  & > a {
    color: $primaryGreen;
    text-decoration: none;
    font-weight: 600;
  }
}

.downloadIOS {
  align-self: center;
  width: 200px;
  height: 65px;
  cursor: pointer;
}
