@import 'styles/config';

.option {
  border-radius: 16px;
  margin-right: rem(8);
  margin-bottom: rem(8);
}

.filterTitle {
  font-weight: 600;
  margin-bottom: rem(6);
}

.filterOption {
  margin: rem(8) 0;
}