@import 'styles/config';

.container {
  flex: 1;
  padding-bottom: rem(100);
  @include lt-desktop {
    align-self: center;
    width: 95%;
  }
}

.header {
  & > p {
    font-family: $heading-font-family;
    font-size: em(32);
    margin-top: rem(4);
    text-transform: uppercase;
  }
}

.content {
  .dayContainer {
    margin-top: rem(32);

    .dayTitle {
      font-weight: bold;
      font-size: em(17);
    }
  }
  .itemContainer {
    .itemTitle {
      font-weight: 700;
    }
  }
}
