@import 'styles/config';

.calendarContainer {
  flex: 1;
  background-color: $bg-black;
  min-height: 375px;
  max-height: 375px;
  max-width: 415px;
  border-radius: 16px;
  padding: rem(32);

  .calendarHeader {
    flex-direction: row;
    justify-content: space-between;

    &>p {
      color: $white;
      font-size: em(22);
      font-weight: 700;
    }

    .calendarControls {
      flex-direction: row;

      &>button {
        margin: 0 rem(5);

        .chevronRight {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.calendar {
  margin-top: rem(22);
}