@import 'styles/config';

.container {
  flex: 1;
  padding-bottom: rem(100);
  align-items: center;
  @include lt-desktop {
    align-self: center;
    width: 95%;
  }
}

.header {
  margin-top: rem(16);
  text-align: center;
  .title {
    font-family: $heading-font-family;
    font-size: em(32);
    margin-top: rem(4);
    text-transform: uppercase;
  }
}

.banner {
  flex: 1;
  width: 100%;
  margin: rem(60) 0;
  background: rgba(61, 163, 186, 0.05);
  border-radius: 16px;
  flex-direction: row;
  align-items: center;
  padding-top: rem(8);
  padding-left: rem(8);
  padding-right: rem(8);
  & > p {
    font-size: em(14);
    flex: 1;
    text-align: center;
    font-weight: 400;
    overflow-wrap: break-word;
  }
}

.imageContainer {
  max-width: 500px;
  max-height: 500px;

  @include phone-only {
    width: 100%;
  }

  & > img {
    object-fit: cover;
    border-radius: 16px;
    max-height: rem(300);
    @include phone-only {
      max-height: rem(220);
    }
  }
}

.content {
  margin-top: rem(32);
  max-width: 500px;

  .contentTitle {
    font-family: $heading-font-family;
    font-size: em(24);
    margin-top: rem(8);
    text-transform: uppercase;
  }

  .challengeSummary {
    margin-top: rem(8);
    margin-bottom: rem(24);
    white-space: pre-wrap;
    font-weight: 400;
  }

  .includesItem {
    flex-direction: row;
    align-items: center;
    margin: rem(12) 0;
    align-items: center;

    .checkContainer {
      background-color: $lightGreen;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      margin-right: rem(16);
    }
  }

  .selectPlanBtn {
    width: 100%;
  }
}
