@import 'styles/config';

.container {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: rem(24) 0;
  @include phone-only {
    padding: rem(24) 0;
  }

  .contentContainer {
    padding: rem(30);
    width: 100%;
    max-width: rem(400);
    min-height: rem(500);
    background: $white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), 0px -1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    @include phone-only {
      padding: rem(24) rem(16);
      width: 95%;
    }
  }
}
