@import 'styles/config';

.container {
  flex: 1;
  width: 100%;
  align-items: center;
  padding-bottom: rem(100);
  position: relative;
}

.contentContainer {
  flex: 1;
  width: 100%;
  max-width: 500px;
  @include lt-desktop {
    width: 95%;
  }

  .workoutTitle {
    margin-top: rem(16);
    font-size: em(45);
    font-weight: 400;
    font-family: $heading-font-family;
    text-align: center;
  }

  .workoutSubTitle {
    margin-top: rem(8);
    text-align: center;
    color: $gray800;
  }

  .headerTitle {
    margin-top: rem(40);
    font-size: em(24);
    font-weight: 400;
    font-family: $heading-font-family;
  }

  .workoutSummary {
    margin-top: rem(8);
    font-weight: 400;
    font-size: em(16);
  }

  .finisherSection {
    max-width: 400px;
  }

  .content {
    align-self: center;
    min-width: 335px;
    @include phone-only {
      width: 95%;
      min-width: auto;
    }
  }
}

.backToWorkoutBtn {
  background-color: $fullTransparent;
  margin-top: rem(30);
  border: 1px solid $gray400;
  & > p {
    color: $primary-text-color;
  }
}



.videoPlayer {
  max-width: 500px;
  width: 100%;
  max-height: 300px;
  min-height: 300px;
  border-radius: 16px;

  @include phone-only {
    max-height: 250px;
    min-height: 250px;
    border-radius: 0;
  }
}
