@import 'styles/config';

.container {
  flex: 1;
  padding-bottom: rem(100);

  @include lt-desktop {
    align-self: center;
    width: 95%;
  }
}

.myProgramContainer {
  .programHeader {
    color: $primaryGreenDark;
    font-size: em(14);
    text-transform: uppercase;
  }

  .programTitle {
    font-family: $heading-font-family;
    font-size: em(32);
    margin-top: rem(4);
    text-transform: uppercase;
  }
}

.content {
  .headerTitle {
    font-family: $heading-font-family;
    font-size: em(32);
    margin-top: rem(12);
    margin-bottom: rem(12);
    text-transform: uppercase;
  }

  .itemContainer {
    .itemTitle {
      font-weight: 700;
    }
  }
}