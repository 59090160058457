/* .ant-switch-checked {
  background-color: #3da3ba;
} */

#root {
  display: block;
}
/* 
@media screen and (max-width: 599px) {
  .ant-carousel .slick-dots li.slick-active button {
    background: #3da3ba !important;
  }
} */
/* 
.ant-input {
  background: #F8FAFC;
  mix-blend-mode: normal;
  border: 1px solid #EEF2F6;
  border-radius: 8px;
  height: 48px;
} */

/* .ant-carousel .slick-dots li.slick-active {
  width: 16px;
} */