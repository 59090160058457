@import 'styles/config';

.container {
  flex: 1;
  width: 100%;
  @include lt-desktop {
    align-self: center;
    width: 95%;
  }
}

.header {
  flex-direction: row;
  align-items: center;
  .categoryIconContainer {
    width: rem(40);
    height: rem(40);
    border-radius: rem(20);
    background-color: $primaryGreenLight;
    justify-content: center;
    align-items: center;
    margin-right: rem(16);
    & > img {
      max-width: rem(18);
    }
  }

  .headerTitle {
    font-family: $heading-font-family;
    font-size: em(40);
  }
}

.loadingContainer {
  margin-top: rem(16);
}

.contentContainer {
  flex: 1;
  max-width: 700px;
  @include lt-desktop {
    max-width: 100%;
  }

  .workoutsContainer {
    margin-top: rem(40);
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @include phone-only {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    & > div {
      min-height: 225px;
      flex: 0 0 48%;
      @include phone-only {
        flex: 1;
        width: 100%;
      }
    }
  }
}

.filterOptionsContainer {
  margin-top: rem(8);
}

.banner {
  margin: rem(16) 0;
  background: rgba(61, 163, 186, 0.05);
  border-radius: 16px;
  flex-direction: row;
  align-items: center;
  padding-top: rem(8);
  padding-left: rem(8);
  padding-right: rem(8);
  & > p {
    flex: 1;
    text-align: center;
    font-weight: 700;
    overflow-wrap: break-word;
  }
}
