@import 'styles/config';

.container {
  display: flex;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid $white;
  justify-content: center;
  align-items: center;

  &.noImage {
    background-color: $primaryGreen;
  }

  &>img {
    object-fit: cover;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid $white;
  }

  &>p {
    color: $white;
    font-size: em(21);
    font-weight: bold;
    line-height: 100%;
  }
}