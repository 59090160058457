.str-chat {


/* --str-chat__message-options-button-size: calc(var(--str-chat__spacing-px) * 32); */

--str-chat__spacing-px: 1px;
}
.str-chat__header-livestream {
    background-color: #dbdde1;
    height: 5rem;
}

button.image-gallery-fullscreen-button {
    height: 0;
    display: none;
}

.str-chat__attachment-list .str-chat__message-attachment--image:not(.str-chat__message-attachment--card) img {
    height: min(80vh, 110vw)
}

.str-chat__message .str-chat__message-options .str-chat__message-actions-box-button:hover,
.str-chat__message .str-chat__message-options .str-chat__message-reply-in-thread-button:hover,
.str-chat__message .str-chat__message-options .str-chat__message-reactions-button:hover {
    transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 350ms;
transform: scale(1.75);

}
.str-chat__message--me .str-chat__message-inner {
    /* margin-inline-start: calc(var(--str-chat__message-options-button-size)*1); */
}

.str-chat__message--other .str-chat__message-inner {
    margin-inline-end: calc(var(--str-chat__message-options-button-size)*1);
}

