@import 'styles/config';

.container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  display: none;
  @include lt-desktop {
    display: flex;
  }
}

.navBar {
  height: 65px;
  width: 100%;
  background-color: $gray50;
}

.content {
  flex: 1;
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.logo {
  width: 150px;
  max-height: 35px;
}
