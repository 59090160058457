@import 'styles/config';

.container {
  height: rem(55);
  border-radius: 16px;
  border: none;
  background-color: #FF5A5F;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  color: $white;
  font-size: em(16);
  font-weight: bold;
  line-height: 140%;
}

.inactive {
  background-color: $primaryGreenLight;
}