@import 'styles/config';

.passwordValidationContainer {
  margin-bottom: rem(20);
  min-height: rem(80);

  &>p {
    font-size: em(12);
    display: flex;
    flex-direction: row;
    align-items: center;

    &>span {
      margin-right: rem(6);
    }
  }

  .valid {
    color: $validGreen;
  }

  .invalid {
    color: $errorRed;
  }
}