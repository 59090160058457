@import 'styles/config';

.container {
  flex: 1;
  width: 100%;
  max-width: 1600px;
  align-self: center;

  @include lt-desktop {
    align-self: center;
    width: 95%;
  }
}

.welcomeText {
  font-weight: 800;
}

.header {
  margin-top: rem(16);
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap-reverse;

  &>div {
    flex: 1;
  }

  .banner {
    flex: 1;
    min-width: rem(300);
    background: rgba(61, 163, 186, 0.05);
    border-radius: 16px;
    flex-direction: row;
    align-items: center;
    padding-top: rem(8);
    padding-left: rem(8);
    padding-right: rem(8);
    margin-bottom: rem(16);

    &>p {
      flex: 1;
      text-align: center;
      font-weight: 700;
      overflow-wrap: break-word;
    }
  }
}

.myProgramContainer {
  flex-direction: row;
  align-items: center;
  max-width: 380px;
  min-width: rem(300);

  .programHeader {
    color: $primaryGreenDark;
    font-size: em(14);
    text-transform: uppercase;
  }

  .programTitle {
    font-family: $heading-font-family;
    font-size: em(32);
    margin-top: rem(4);
    text-transform: uppercase;
  }

  .progressContainer {
    margin-left: rem(20);
  }
}

.contentContainer {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  &>div {
    flex: 0 0 40%;
    margin-top: rem(40);
  }

  // @include lt-desktop {
  //   flex-direction: column;
  // }

  @include phone-only {
    &>div {
      flex: 1 0 auto;
    }
  }


  .getStartedBtn {
    margin-top: rem(16);
    background-color: $primaryGreenLight;
    height: 36px;
    border-radius: 30px;

    &>p {
      color: $primaryGreenDark;
    }
  }
}


.contentHeaderTitle {
  margin-top: rem(24);
  font-size: em(24);
  font-family: $heading-font-family;
}

.todaysRecipes {
  max-width: 100%;

  // .recipesContainer {
  //   flex-direction: row;
  //   overflow-x: hidden;

  //   &>div {
  //     min-width: rem(160);

  //     // margin-right: rem(24);
  //     // margin-bottom: rem(24);
  //     @include phone-only {
  //       &>div {
  //         max-width: rem(280);

  //         &>img {
  //           height: rem(280);
  //         }
  //       }
  //     }
  //   }
  // }
}