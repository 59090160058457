@import 'styles/config';

.headline {
  font-family: $heading-font-family;
  font-weight: normal;
  font-size: em(32);
  line-height: 100%;
  color: $black;
}

.subHeading {
  font-size: em(15);
  line-height: 140%;
  font-weight: 400;
  color: $gray600;
  margin-top: rem(8);
  margin-bottom: rem(8);
}

.optionsContainer {
  margin: rem(24) 0;
}

.banner {
  flex: 1;
  margin-top: rem(8);
  margin-bottom: rem(32);
  background: rgba(61, 163, 186, 0.05);
  border-radius: 16px;
  flex-direction: row;
  align-items: center;
  padding-top: rem(8);
  padding-left: rem(8);
  padding-right: rem(8);
  & > p {
    flex: 1;
    text-align: center;
    font-weight: 400;
    overflow-wrap: break-word;
  }
}

.nextBtn {
  margin-top: auto;
}

.skipLink {
  color: $primaryGreen;
  font-size: em(15);
  align-self: flex-end;
  text-decoration: none;
  font-weight: 600;
  margin-bottom: rem(10);
}

.planContainer {
  margin-top: rem(30);
  .planBackgroundContainer {
    height: 200px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(25, 158, 181, 0) 0%, rgba(2, 158, 183, 0.38) 100%);
    border-radius: 16px;
    cursor: pointer;
  }
  .planTitle {
    font-family: 'Trim Poster';
    font-size: em(32);
    font-weight: 300;
    background-color: $black;
    color: $white;
    padding: rem(2) rem(7);
  }
  .planMeta {
    margin-top: rem(8);
    color: $white;
    font-weight: 600;
    font-size: em(13);
  }
  .planDescription {
    font-weight: 400;
    margin-top: rem(8);
  }
}

.planBackgroundContainerSelected {
  border: 1.5px solid rgba(61, 163, 186, 1);
}

.startPlanBtn {
  margin-top: rem(60);
}

.stepsContainer {
  margin-bottom: rem(35);
  justify-content: center;

  .stepIndicatorsContainer {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .indicator {
    flex: 1;
    height: rem(4);
    border-radius: rem(8);
    background-color: $primaryGreen30;
    margin: 0 rem(2);
    max-width: rem(45);
  }

  .activeIndicator {
    background-color: $primaryGreenDark;
  }
}
