@import 'styles/config';

.container {
  width: 300px;
  min-width: 300px;
  background-color: $gray50;
  flex-direction: column;
  padding: rem(32) rem(18);
  z-index: 11;
  -webkit-animation: slideIn 0.5s forwards;
  -moz-animation: slideIn 0.5s forwards;
  animation: slideIn 0.5s forwards;
  // overflow-y: hidden;

  @include lt-desktop {
    min-width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // margin-top: rem(65);
    padding-top: 0;


    .logo {
      display: none;
    }
  }
}

.downloadIOS {
  align-self: center;
  width: 250px;
  height: 65px;
  cursor: pointer;
  // margin-bottom: rem(24);
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

.logo {
  max-width: 120px;
}

.linksContainer {
  flex: 1;
  width: 100%;
  // margin: rem(32) 0;

  //   & > a {
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     flex: 0 0 auto;
  //     text-decoration: none;
  //     color: $gray500;
  //     padding: rem(16);
  //     font-size: em(17);
  //     margin-bottom: rem(8);
  //     border-radius: 12px;

  //     &:hover {
  //       background-color: $primaryGreenLight;
  //     }

  //     .icon {
  //       margin-right: rem(20);
  //       path,
  //       circle {
  //         stroke: $gray500;
  //       }
  //     }
  //     .fillIcon {
  //       margin-right: rem(20);
  //       path {
  //         fill: $gray500;
  //       }
  //     }
  // }

  // .activeLink {
  //   font-weight: bold;
  //   color: white;
  //   background-color: $primaryGreenDark;

  //   &:hover {
  //     background-color: $primaryGreenDark;
  //   }

  //   .icon {

  //     path,
  //     circle {
  //       stroke: $white;
  //     }
  //   }

  //   .fillIcon {
  //     path {
  //       fill: $white;
  //     }
  //   }
  // }
}

.leanStreakContainer {
  &>p {
    font-family: $heading-font-family;
    font-size: em(24);
    text-transform: uppercase;
    margin-bottom: rem(16);
    // margin-top: rem(8);
  }
}