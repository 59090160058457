$black: #000000;
$white: #ffffff;
$gray50: #f7fafc;
$gray100: #edf2f7;
$gray200: #e2e8f0;
$gray300: #cbd5e0;
$gray400: #a0aec0;
$gray500: #718096;
$gray600: #4a5568;
$gray700: #2d3748;
$gray800: #1a202c;
$gray900: #171922;
$darkGray: #8995a3;
$errorRed: #e9615f;
$validGreen: #22b17f;
$lightGreen: #00c156;
$subPink: #c49FFa;
$orange: #ffb537;
$primaryGreen: #6bbed1;
$primaryGreenDark: #3da3ba;
$primaryGreenDarker: #008aa2;
$primaryGreenLight: #d3ecf1;
$primaryGreen30: rgba(61, 163, 186, 0.3);
$gold: #ffd700;

$fullTransparent: rgba(0, 0, 0, 0);
$primary-text-color: $gray900;
$background-color: $white;
$bg-black: #1a182d;

:export {
  $black: #000000;
  $white: #ffffff;
  $gray50: #f7fafc;
  $gray100: #edf2f7;
  $gray200: #e2e8f0;
  $gray300: #cbd5e0;
  $gray400: #a0aec0;
  $gray500: #718096;
  $gray600: #4a5568;
  $gray700: #2d3748;
  $gray800: #1a202c;
  $gray900: #171922;
  $darkGray: #8995a3;
  $errorRed: #e9615f;
  $validGreen: #22b17f;
  $subPink: #c49FFa;
  $orange: #ffb537;
  $primaryGreen: #6bbed1;
  $primaryGreenDark: #3da3ba;
  $primaryGreenDarker: #008aa2;
  $primaryGreenLight: #d3ecf1;
  $primaryGreen30: rgba(61, 163, 186, 0.3);
  $fullTransparent: rgba(0, 0, 0, 0);

  $primary-text-color: $gray900;
  $background-color: $white;
  $bg-black: #1a182d;
}
