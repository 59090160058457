@import './colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Trim Poster';
  src: local('Trim Poster'), url(../../assets/fonts/TrimPoster-Slender.otf) format('opentype');
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(../../assets/fonts/SFPRODISPLAYREGULAR.OTF) format('opentype');
}

@font-face {
  font-family: 'SF Pro Display Bold';
  src: local('SF Pro Display'), url(../../assets/fonts/SFPRODISPLAYBOLD.OTF) format('opentype');
}

@font-face {
  font-family: 'BebasNeue-Regular';
  src: local('BebasNeue-Regular'), url(../../assets/fonts/BebasNeue-Regular.otf) format('opentype');
}

$default-font-size: 16px;
$default-font-family: 'Inter', sans-serif;
$default-font-color: $gray900;

$heading-font-family: 'Trim Poster';
$sf-pro-display-font-family: 'SF Pro Display';
$sf-pro-display-bold-font-family: 'SF Pro Display Bold';
