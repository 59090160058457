@import 'styles/config';

.container {
  flex: 1;
  width: 100%;
  align-items: center;
  @include lt-desktop {
    align-self: center;
    width: 95%;
  }
}

.contentContainer {
  flex: 1;
  max-width: 700px;
  @include lt-desktop {
    max-width: 100%;
  }
}

.header {
  flex: 1;
  min-height: 300px;
  max-height: 300px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: rem(12);
  text-align: center;

  .headerImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .headerTitle {
    font-family: $heading-font-family;
    font-size: em(45);
    color: $white;
    z-index: 9;
  }
  .headerDescription {
    color: $white;
    margin-top: rem(8);
    z-index: 9;
  }
  .headerBtn {
    margin-top: rem(24);
    min-width: rem(165);
    z-index: 9;
    padding-left: rem(24);
    padding-right: rem(24);
  }
}

.categoryRow {
  background-color: $gray50;
  max-height: 125px;
  min-height: 125px;
  border-radius: 16px;
  margin: rem(32) 0;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;

  // horizontal padding
  .emptyDiv {
    min-width: rem(24);
    height: 100%;
  }

  .categoryContainer {
    flex: 1;
    margin: 0 rem(12);
    padding: rem(12) 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: rem(75);
    border-radius: 8px;
    &:hover {
      background-color: $primaryGreen30;
    }

    & > p {
      color: $primaryGreenDark;
      font-size: em(13);
      font-weight: 600;
      text-align: center;
      white-space: nowrap;
    }
  }

  .categoryIconContainer {
    width: rem(40);
    height: rem(40);
    border-radius: rem(20);
    background-color: $primaryGreenLight;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(4);
    & > img {
      object-fit: contain;
      max-width: 16px;
    }
    & > svg {
      max-width: 18px;
    }
  }
}

.classTypesContainer {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @include phone-only {
    flex-direction: column;
    align-items: center;
  }

  .classTypeContainer {
    display: flex;
    flex: 1;
    min-width: rem(300);
    max-width: rem(300);
    min-height: rem(200);
    background: linear-gradient(180deg, rgba(46, 49, 62, 0) 0%, rgba(34, 36, 45, 0.7) 100%);
    border-radius: 12px;
    justify-content: center;
    margin-bottom: rem(32);
    margin-right: rem(32);
    overflow: hidden;
    position: relative;
    @include phone-only {
      max-width: none;
      margin-right: rem(0);
      width: 100%;
    }

    .classTypeImage {
      position: absolute;
      height: 100%;
      object-fit: cover;
      max-width: 100%;
      width: 100%;
    }

    .classTypeTitle {
      font-family: $heading-font-family;
      font-size: em(32);
      color: $white;
      // max-width: 75%;
      z-index: 9;
      align-self: center;
      width: 100%;
      text-align: left;
      padding-left: rem(20);
    }
  }
}
