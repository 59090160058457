@import 'styles/config';

.contentHeaderTitle {
  margin-top: rem(24);
  font-size: em(24);
  font-family: $heading-font-family;
}

.todaysWorkout {
  max-width: 380px;
  min-width: 335px;
  @include phone-only {
    min-width: auto;
    width: 100%;
  }
}
