@import 'styles/config';

.filterOption {
  margin: rem(8) 0;

  .filterOptionsTitle {
    font-size: em(17);
    font-weight: bold;
  }

  .filtersRow {
    flex-direction: row;
    padding-top: rem(8);
    padding-bottom: rem(16);
    overflow-x: auto;
    overflow-y: hidden;
    // possibly wrap instead here.
    .filterItem {
      height: 30px;
      white-space: nowrap;
      text-align: center;
      display: flex;
      border: 1px solid $gray400;
      border-radius: 16px;
      padding: rem(4) rem(16);
      margin-right: rem(8);
      margin-bottom: rem(8);

      &:hover {
        background-color: $primaryGreen30;
        cursor: pointer;
      }

      .filterTitle {
        font-weight: 600;
      }
    }

    .activeFilter {
      background-color: $gray900;
      border-color: $gray900;

      &:hover {
        background-color: $gray900;
      }

      .filterTitle {
        color: $white;
      }
    }
  }
}
