@import 'styles/config';

.playBtn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  // background-color: $primaryGreenDark;
  justify-content: center;
  align-items: center;
  color: $white;
  box-shadow: 0px 8px 15px rgba(61, 163, 186, 0.1);
  cursor: pointer;
  z-index: 2;
}

.workoutItem {
  max-width: 100%;
  height: 225px;
  justify-content: flex-end;
  padding: rem(16);
  border-radius: 16px;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  overflow: hidden;

  .completedCheck {
    z-index: 9;
    position: absolute;
    top: rem(16);
    left: rem(16);
  }

  .favoriteStar {
    z-index: 9;
    position: absolute;
    top: rem(16);
    right: rem(16);
  }

  .workoutInfo {
    flex: 1;
    z-index: 2;
  }

  .workoutImagePreview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .workoutTitle {
    color: $white;
    font-size: em(22);
    font-weight: bold;
  }

  .workoutDescription {
    margin-top: rem(8);
    color: $gray200;
    font-size: em(15);
  }
}

.finisherItem {
  margin: rem(16) 0;
  padding: rem(16);
  background: $bg-black;
  border-radius: 16px;
  flex-direction: row;

  .finisherPreviewImg {
    height: 90px;
    width: 90px;
    border-radius: 8px;
    object-fit: cover;
  }

  .emptyPreviewImage {
    height: 90px;
    width: 90px;
    border-radius: 8px;
    border: 0.5px solid $gray100;
  }

  .workoutInfo {
    flex: 1;
    margin-left: rem(16);
    margin-right: rem(8);
  }

  .workoutTitle {
    color: $white;
    font-size: em(17);
    font-weight: bold;
  }

  .workoutDescription,
  .workoutDuration {
    margin-top: rem(8);
    color: $white;
    font-size: em(13);
  }

  .workoutDuration {
    margin-top: rem(16);
    font-size: em(15);
  }

  .playBtn {
    align-self: flex-end;
  }
}

.isFavorite {
  fill: $gold;

  path {
    stroke: $gold;
  }
}

.lockedOverlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
}

.lockedText {
  position: absolute;
  color: $black;
  font-size: em(16);
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  padding-right: 12px;
  padding-left: 12px;
}