@import 'styles/config';

.container {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: rem(20);
  background: $white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px -1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  & > div {
    flex-direction: row;
    align-items: center;
    & > svg {
      margin-right: rem(8);
    }
    .title {
      font-size: em(17);
      text-align: center;
      font-weight: bold;
    }
    .description {
      text-align: center;
      font-size: em(12);
      color: $darkGray;
    }
  }

  .currentContainer {
    .title {
      color: $errorRed;
    }
  }

  .divider {
    height: 90%;
    width: 1px;
    background-color: $gray100;
  }

  .allTimeContainer {
    & > svg {
      path {
        fill: $primaryGreenDark;
      }
    }

    .title {
      color: $primaryGreenDark;
    }
  }

  .lastStreak {
    .negativeCircle {
      transform: rotate(180deg);
      path {
        fill: red;
      }
      circle {
        stroke: red;
      }
    }
  }
}
