@import 'styles/config';


.container {
  flex: 1;
  align-items: center;
  padding-bottom: rem(100);
  width: 100%;
  padding-bottom: 0;

  @include lt-desktop {
    align-self: center;
    width: 100vw;
  }

  @include phone-only {
    width: 100vw;
    padding-left: 1.5%;
  }
}

.header {
  .headerTitle {
    font-family: $heading-font-family;
    font-size: em(32);
    margin-top: rem(4);
    text-transform: uppercase;
    text-align: center;
  }
}

.banner {
  flex: 1;
  max-width: 335px;
  margin-top: rem(12);
  background: rgba(61, 163, 186, 0.05);
  border-radius: 16px;
  flex-direction: row;
  align-items: center;
  padding-top: rem(8);
  padding-left: rem(8);
  padding-right: rem(8);
  align-self: center;

  &>p {
    flex: 1;
    text-align: center;
    font-weight: 700;
    overflow-wrap: break-word;
  }
}

.contentContainer {
  margin-top: rem(32);
  width: 100%;
}


.challengeListContainer {
  background-color: aliceblue;

  .content {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include phone-only {
      flex-direction: column;
      justify-content: center;
    }

    &>button {
      margin-bottom: rem(16);
    }
  }
}

.arrow {
  @include phone-only {
    display: none !important;
  }
}

.sectionTitle {
  font-family: $heading-font-family;
  font-size: em(20);
  margin-top: rem(4);
  text-transform: uppercase;
  text-align: left;
  margin-left: 10px;
}

.headerDescription {
  font-size: em(15);
  margin-top: rem(4);
  text-align: center;
  padding: rem(20);
}

.arrowLeft {
  left: 0;
}

.arrowRight {
  right: 0;
}