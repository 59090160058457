@import 'styles/config';

.container {
  flex: 1;
  @include lt-desktop {
    align-self: center;
    width: 95%;
  }
}

.myProgramContainer {
  .programHeader {
    color: $primaryGreenDark;
    font-size: em(14);
    text-transform: uppercase;
  }

  .programTitle {
    font-family: $heading-font-family;
    font-size: em(32);
    margin-top: rem(4);
    text-transform: uppercase;
  }
  .programStartsIn {
    color: $gray400;
    font-size: em(13);
    & > span {
      color: $primaryGreen;
    }
  }

  .challengeProgress {
    flex-direction: row;
    align-items: center;
    .progressContainer {
      height: 3px;
      width: 120px;
      background-color: $gray500;
      margin-right: rem(8);
      border-radius: 8px;

      .progress {
        background-color: $primaryGreenDark;
        width: 0%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }
}

.content {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 800px;
  margin-top: rem(30);
  @include lt-desktop {
    max-width: 100%;
  }
  @include phone-only {
    flex-direction: column;
  }

  .leftContent {
    flex: 1;
    margin-right: rem(50);
    @include phone-only {
      flex-direction: column;
      margin-right: 0;
    }
    & > div:first-of-type {
      margin-bottom: rem(24);
    }

    .notStartedYetContainer {
      max-width: rem(415);
      margin-top: rem(16);
      .banner {
        background: rgba(61, 163, 186, 0.05);
        border-radius: 16px;
        flex-direction: row;
        align-items: center;
        padding: rem(16);
        & > p {
          flex: 1;
          text-align: center;
          overflow-wrap: break-word;
          span {
            font-weight: bold;
          }
        }
      }

      .seeWorkoutsBtn {
        margin-top: rem(16);
      }

      .startChallengeNowBtn {
        background-color: $fullTransparent;
        margin-top: rem(8);
        & > p {
          color: $gray500;
          font-size: em(15);
          font-weight: normal;
        }
      }
    }
  }

  .rightContent {
    flex: 1;
    width: 100%;
  }

  .dateHeader {
    font-size: em(24);
    font-family: $heading-font-family;
  }

  .contentHeaderTitle {
    margin-top: rem(24);
    font-size: em(24);
    font-family: $heading-font-family;
  }

  .dayOptionsContainer {
    margin-top: rem(16);
  }

  .viewAllBtn {
    width: 100%;
    align-self: center;
    max-width: rem(350);
    margin-bottom: rem(24);
    background-color: $fullTransparent;
    border: 1px solid $gray300;
    & > p {
      color: $default-font-color;
    }
    &:hover {
      border-color: $primaryGreen;
    }
  }
}
