/**
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */
$browser-context: 16; // Default

// Function for converting a px based font-size to rem.
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}
