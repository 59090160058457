@import 'styles/config';

.svg {
  display: block;
  max-width: 100%;
}

.svgCircleBg {
  fill: none;
  stroke: $gray200;
}

.svgCircle {
  fill: none;
  //   stroke: #c393ff;
}

.svgCircleText {
  font-size: em(8);
  text-anchor: middle;
  fill: $primary-text-color;
  font-weight: bold;
}