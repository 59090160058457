@import 'styles/config';

.container {
  flex: 1;
  align-items: center;
  padding-bottom: rem(100);
}

.contentContainer {
  flex: 1;
  max-width: 500px;
  width: 100%;
  @include phone-only {
    width: 95%;
  }

  .pageTitle {
    font-size: em(32);
    font-family: $heading-font-family;
  }
}

.dayContainer {
  margin-top: rem(32);

  .dayTitle {
    font-weight: bold;
    font-size: em(17);
  }

  .photoRow {
    margin-top: rem(16);
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  .photoContainer {
    margin-right: rem(16);
    margin-bottom: rem(16);
    max-width: 180px;
    height: 260px;
    border-radius: 16px;
    object-fit: cover;
    cursor: pointer;
  }

  .uploadContainer {
    margin-right: rem(16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    max-width: 180px;
    height: 260px;
    border: 2px dashed $gray200;
    border-radius: 16px;
    padding: 0 rem(16);
    & > p {
      margin-top: rem(8);
      color: $gray200;
      font-weight: bold;
      text-align: center;
    }
  }
}
