@import 'styles/config';

.container {
  flex: 1;
  width: 100%;
  padding-bottom: rem(100);
  @include lt-desktop {
    align-self: center;
    width: 95%;
  }
}

.header {
  & > p {
    font-family: $heading-font-family;
    font-size: em(32);
    margin-top: rem(4);
    text-transform: uppercase;
    margin-bottom: rem(16);
  }
}

.descriptionBanner {
  max-width: 415px;
  background: rgba(61, 163, 186, 0.05);
  border-radius: 16px;
  flex-direction: row;
  align-items: center;
  padding-left: rem(8);
  padding-right: rem(8);
  & > p {
    flex: 1;
    text-align: center;
    font-weight: 700;
    overflow-wrap: break-word;
    padding-left: rem(16);
    padding-right: rem(16);
  }
}

.calendarSection {
  margin-right: rem(48);
  max-width: 400px;
  @include phone-only {
    margin-right: 0;
  }
  .mealOptionsContainer {
    flex: 1;
    max-width: 335px;
    min-width: 275px;
    margin-top: rem(16);
    margin-bottom: rem(16);
    @include phone-only {
      max-width: none;
    }

    .changeBtn {
      margin: rem(16) 0;
      background-color: $background-color;
      border: 1.5px solid $fullTransparent;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px -1px 4px rgba(0, 0, 0, 0.08);
    }
    .changeBtnText {
      color: $primaryGreenDark;
    }

    .scheduledKeyTitle {
      font-size: em(17);
      font-weight: 700;
      margin-bottom: rem(16);
    }

    .scheduledContainer {
      cursor: pointer;
      flex-direction: row;
      align-items: center;
      background: $white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px -1px 4px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      border: 1.5px solid rgba(0, 0, 0, 0);
      margin-bottom: rem(16);
      padding: rem(20) rem(16);

      &:hover {
        border: 1.5px solid $primaryGreen;
      }

      .image {
        width: rem(64);
        height: rem(64);
        border-radius: rem(16);
        margin-right: rem(16);
      }

      .optionContent {
        flex: 1;
        & > p {
          font-weight: bold;
        }
        & > p:nth-child(1) {
          color: $black;
          font-size: em(17);
          margin-bottom: rem(2);
        }

        & > p:nth-child(2) {
          color: $darkGray;
          font-size: em(13);
        }
      }
    }
  }
}

.contentContainer {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @include lt-desktop {
    max-width: 100%;
  }
  @include phone-only {
    flex-direction: column;
  }

  & > div {
    flex: 1;
    @include phone-only {
      width: 100%;
    }
  }
}

.categoryRow {
  flex-direction: row;
  flex-wrap: wrap;

  & > a {
    text-decoration: none;
  }

  .categoryItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    border: 1px solid $fullTransparent;
    padding: rem(4) rem(16);
    margin-right: rem(16);
    margin-bottom: rem(16);
    & > p {
      color: $gray600;
      font-weight: 600;
    }
  }
  .activeCategoryItem {
    background-color: $primaryGreenDark;
    border-color: $primaryGreenDark;

    & > p {
      color: $white;
    }
  }
}

.recipeItemsContainer {
  min-width: 370px;
  margin-top: rem(24);
  flex-direction: row;
  flex-wrap: wrap;

  & > div {
    margin-bottom: rem(16);
    margin-right: rem(16);
  }

  @include phone-only {
    min-width: auto;
    flex-direction: column;
    align-items: center;
    min-height: 450px;
    & > div {
      margin-bottom: rem(16);
      margin-right: 0;
      width: 100%;
      & > div {
        max-width: 420px;
      }
    }
  }
}
