@import 'styles/config';

.container {
  &>p {
    margin-top: rem(8);
  }
}

.input {
  height: rem(48);
  padding: rem(12) rem(16);
  justify-content: center;
  background-color: $gray50;
  border: 1px solid $gray100;
  border-radius: 8px;

  font-size: em(17);
  color: $gray900;
  font-weight: bold;

  &::placeholder {
    color: $gray500;
    font-weight: normal;
  }
}