@import 'styles/config';

.workoutsContainer {
  margin-top: rem(40);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @include phone-only {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: rem(15);
    padding-right: rem(15);
  }

  &>div {
    min-height: 225px;
    flex: 0 0 48%;

    @include phone-only {
      flex: 1;
      width: 100%;
    }
  }
}

.loadingContainer {
  margin-top: rem(16);
}