@import 'styles/config';

.container {
  flex: 1;
  width: 100%;
  align-items: center;
  position: relative;
  @include lt-desktop {
    align-self: center;
    width: 95%;
  }
}

.backBtn {
  align-self: flex-start;
  margin-bottom: rem(24);
  padding: 0;
}

.contentContainer {
  max-width: 580px;
  width: 100%;
  align-items: center;
  padding-bottom: rem(100);
}

.goToMeasurementsBanner {
  margin-top: rem(24);
  background: rgba(61, 163, 186, 0.05);
  border-radius: 16px;
  padding: rem(16) rem(24);
  & > p,
  & > a {
    flex: 1;
    text-align: left;
    font-weight: 700;
    overflow-wrap: break-word;
  }
  & > a {
    margin-top: rem(8);
  }
}

.photoContainer {
  border-radius: 16px;
  width: 100%;

  div {
    height: rem(500);
    @include phone-only {
      min-height: none;
      max-height: rem(300);
    }
  }

  img {
    width: auto;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
}

.header {
  margin: rem(40) 0;
  text-align: center;
  .category {
    font-style: italic;
    font-weight: bold;
    font-size: 15px;
    color: $primaryGreenDark;
    margin-bottom: rem(8);
    text-transform: uppercase;
  }
  .title {
    font-size: em(45);
    font-weight: 400;
    font-family: $heading-font-family;
  }
  .metadata {
    font-size: em(15);
  }
}

.viewOptionRow {
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  .viewOption {
    border: 1px solid $fullTransparent;
    border-radius: 16px;
    padding: rem(4) rem(16);
    line-height: em(24);
    font-size: em(15);
    font-weight: 600;
    color: $primary-text-color;
    background-color: $background-color;
  }

  button:first-of-type {
    margin-right: rem(10);
  }
  button:nth-of-type(2) {
    margin-left: rem(10);
  }

  @media screen and (max-width: #{$screen-sm-max}) {
    button {
      margin-bottom: rem(10);
    }
    button:first-of-type {
      margin-right: rem(0);
    }
    button:nth-of-type(2) {
      margin-left: rem(0);
    }
  }

  .active {
    color: $white;
    background-color: $primaryGreenDark;
  }
}
.content {
  width: 100%;
}

.viewContent {
  margin-top: rem(40);
}

.ingredients,
.instructions {
  white-space: pre-wrap;
  font-weight: 400;
}

.addMealBtn {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 0;
  max-width: 580px;

  @include lt-desktop {
    max-width: none;
  }

  background-color: $primaryGreen;
  & > p {
    color: $white;
  }

  &.active {
    background-color: $gray50;
    border: 2px solid $primaryGreenDark;
    & > p {
      color: $primaryGreenDark;
    }
  }
}
