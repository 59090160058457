@import './config.scss';
@import 'antd/dist/antd.css';

html {
  margin: 0;
  padding: 0;
  font-size: $default-font-size;
}

@-ms-viewport {
  width: device-width;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $default-font-family;
  color: $default-font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

textarea:focus,
input:focus {
  outline: none;
}




.errorText {
  font-size: em(13);
  color: $errorRed;
  font-weight: 600;
}

.loadingIndicator {
  flex-direction: row;
  align-self: center;
  justify-content: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(46, 49, 62, 0) 0%, rgba(34, 36, 45, 0.7) 100%);
  z-index: 1;
  border-radius: 16px;
}

/** Override styles of Video JS */

.video-js {
  .vjs-tech {
    border-radius: 16px;
  }

  .vjs-poster {
    border-radius: 16px;
  }
}

.vjs-control-bar {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  @include phone-only {
    border-radius: 0;
  }
}

.videoOverlay {
  max-width: 100% !important;
  /*background-color: rgba(0, 0, 0, 0.1);*/

  padding: rem(12) rem(18);
}



video::-webkit-media-controls-panel,
video::-webkit-media-controls-panel-container,
video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}


video:focus {
  outline: none;
}

/** Override styles of default calendar */
.react-calendar {
  display: block;
  background-color: $fullTransparent;
  border: none;
  width: 350px;
  max-width: 100%;

  @include phone-only {
    width: 100%;
  }

  & div {
    display: initial;
    flex-direction: row;
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;

    abbr {
      color: $gray500;
      text-decoration: none;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__tile {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: $gray500 !important;
  }

  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    color: $white;
    font-size: em(14);
    font-weight: 500;
    font-family: $default-font-family;
    border-radius: 12px;
    border: 1px solid $fullTransparent;

    &.scheduled {
      border: 1px solid $gold;

      &.flexDay {
        color: #854d0e
      }
    }

    &.flexDay {
      color: #eab308
    }

    &.complete {
      background: $lightGreen;
      border: 1px solid $lightGreen;

      &.flexDay {
        color: #854d0e
      }

    }
  }

  .react-calendar__tile:disabled {
    background-color: #f0f0f0;

  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    border: 1px solid #e42a6c;
    background-color: $fullTransparent;
  }

  .react-calendar__tile--now {
    border: 1px solid $primaryGreenDark;
  }

  .react-calendar__tile--active {
    background: linear-gradient(270deg, #e9615f 34.57%, #e42a6c 100%) !important;
    border: 1px solid $fullTransparent;
    border-radius: 12px;
    color: white;

    &.complete {
      border: 1px solid $lightGreen;
    }
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    border: 1px solid #e42a6c;
    background: linear-gradient(270deg, #e9615f 34.57%, #e42a6c 100%);

    &.scheduled {
      border: 1px solid $gold;
    }

    &.complete {
      border: 1px solid $lightGreen;
    }

    &.flexDay {
      color: #713f12
    }

  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: $gray200;
  }
}

// Override Carousel styles

.embla {
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  height: 100%;

  div {
    display: flex;
    height: 100%;
  }

  @include phone-only {
    max-width: 100%;
  }
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  max-height: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  min-width: 100%;

  @include phone-only {
    max-width: 100%;
  }
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  border: 0;
  fill: $white;
  padding: 0;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button--prev {
  left: rem(10);
}

.embla__button--next {
  right: rem(10);
  transform: rotate(180deg);
}

// notyf styles
.notyf__toast {
  max-width: 600px !important;

  @media screen and (max-width: 800px) {
    width: 90% !important;
  }
}

.notyf__wrapper {
  flex-direction: row;
}

// Stripe Elements

.StripeElement {
  margin-top: rem(16);
  padding: rem(12) rem(12);
  justify-content: center;
  background-color: $gray50;
  border: 1px solid $gray100;
  border-radius: 8px;

  font-size: em(17);
  color: $gray900;
  font-weight: bold;
}

// ReactPopup js

.popup-content {
  margin: auto;
  background: $background-color;
  width: 50%;
  border-radius: rem(8);

  @include lt-desktop {
    width: 75%;
  }

  @include phone-only {
    width: 90%;
  }
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

// React DatePicker

.react-datepicker__input-container {
  height: rem(38);
  padding: rem(2) rem(8);
  justify-content: center;
  background-color: $white;
  border: 1px solid $gray700;
  border-radius: 4px;

  &>input {
    height: 100%;
    font-size: em(15);
    color: $gray900;
    border: none;
  }

  &::placeholder {
    color: $gray500;
    font-weight: normal;
  }
}