@import 'styles/config';

.headline {
  font-family: $heading-font-family;
  font-weight: normal;
  font-size: em(32);
  line-height: 100%;
  color: $black;
}

.forgotPasswordLink {
  color: $primaryGreen;
  text-decoration: none;
  font-size: em(15);
}

.inputsContainer {
  margin-top: rem(40);

  .input {
    margin-bottom: rem(16);
  }
}

.createAccountLink {
  font-size: em(15);
  align-self: center;
  margin: rem(20) 0;
  & > a {
    color: $primaryGreen;
    text-decoration: none;
    font-weight: 600;
  }
}

.errorMessage {
  margin-bottom: rem(16);
  align-self: center;
}

.footer {
  margin-top: auto;
}
