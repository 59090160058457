@import 'styles/config';

.container {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: rem(24) rem(16);
  background-color: $gray50;
  border-radius: 8px;
}

.optionTitle {
  flex: 1;
  font-size: em(16);
  font-weight: 600;
  text-align: start;
  margin-left: rem(16);
}

.chevron {
  transform: rotate(180deg);
  path {
    fill: $gray900;
  }
}
