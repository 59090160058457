@import 'styles/config';

.container {
  flex: 1;
  flex-direction: row;
  background-color: $background-color;
  height: 100%;
  overflow: hidden;

}

.content {
  flex: 1;
  padding: rem(32) rem(60);
  overflow-y: auto;

  @include lt-desktop {
    padding: rem(32) rem(0);
    padding-top: rem(68);
  }
}