@import 'styles/config';

.subYearly {
 background: linear-gradient(0deg, #C09DF5, #C09DF5), linear-gradient(0deg, #C09DF5, #C09DF5), #C09DF5;
  padding: rem(18);
}

.subMonthly {
  background-color: $primaryGreenDarker;
}

.title {
  font-size: em(50);
  font-family: $heading-font-family;
  font-weight: normal;
  line-height: 100%;
  padding-left: rem(18);
  padding-bottom: rem(10);
}

.subscriptionTitle {
  font-size: rem(64);
  font-family: $heading-font-family;
  font-weight: normal;
  line-height: 100%;
  display: flex;
  justify-content: flex-start;

  &__cents {
    font-size: rem(30);
    line-height: rem(30);
    padding-top: rem(10);
  }
}

.subYearly {
  .subscriptionTitle, .subHeading, .sale, .subscriptionSummary {
    color: $white; 
  }
}

.saleContainer {
  margin-left: auto;
  margin-right: rem(20);
  margin-top: rem(6);
}

.sale {
  font-size: em(13);
  z-index: 1;
}

.yellowStrikethrough {
  z-index: 2;
  transform: translate(0, -110%);
}

.horizontalContainer {
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: space-between;
 
  margin-left: rem(18);
  margin-right: rem(18);

  @include phone-only {
    margin-top: rem(10);
    margin-bottom: rem(0);
  }
}

.pricing {
  background: #A779EC;
  border-radius: 4px;
  font-size: em(12);
  margin-left: rem(18);
  padding-left: rem(8);
  padding-right: rem(8);
  padding-top: rem(2);
  padding-bottom: rem(2);
}

.pricingBold {
  background: #8041BE;
  border-radius: 4px;
  font-size: em(14);
  margin-left: rem(18);
  padding-left: rem(8);
  padding-right: rem(8);
  padding-top: rem(2);
  padding-bottom: rem(2);
  font-family: $default-font-family;
  font-size: em(13);
  line-height: 100%;
  font-weight: bold;
  font-style: italic;
}

.subscriptionSummary {
  padding-top: rem(8);
  padding-left: rem(18);
  padding-right: rem(18);
  text-align: left;
  font-size: em(13);
}



.subHeading {
  font-family: $default-font-family;
  font-size: em(13);
  line-height: 100%;
  font-weight: bold;
  font-style: italic;
  padding-top: rem(18);
  padding-left: rem(18);

  @include phone-only {
    padding-bottom: rem(18);
  }
}

.subHeadingYearly {
  font-family: $default-font-family;
  font-size: em(13);
  line-height: 100%;
  font-weight: bold;
  font-style: italic;
  padding-top: rem(18);
  padding-left: rem(18);
  color: #8041BE;
}

.subHeadingBlue {
  font-family: $default-font-family;
  font-size: em(13);
  line-height: 100%;
  font-weight: bold;
  font-style: italic;
  padding-top: rem(18);
  padding-left: rem(18);
  color: #73E4FD;
}


.savings {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.row {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-right: rem(10);
}

.fullWidth {
  width: 100%;
  padding-right: em(16);
}

.topPadding {
  margin-top: rem(18);
}

.bestDeal {
  margin-top: rem(18);
  margin-left: auto;
}

.optionContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-bottom: rem(10);
  margin-bottom: rem(12);
  border-radius: 16px;
  border: 1px solid $fullTransparent;

  color: $white;

  &.subYearlyActive {
    border: 2px solid $orange;
   background: linear-gradient(0deg, #C09DF5, #C09DF5), linear-gradient(0deg, #C09DF5, #C09DF5), #C09DF5;
  }

  &.subMonthlyActive {
    border: 2px solid $orange;
    background-color: $primaryGreenDarker;
  }

  .bestValueBox {
    flex: 1;
    width: 100%;
    text-align: center;
    background-color: $primaryGreenDark;
    padding: rem(8);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border: 1px solid $primaryGreenDark;
    & > p {
      color: $white;
      font-size: em(13);
      font-weight: bold;
    }
  }

  &.activeOption {
    border: 2px solid $primaryGreenDark;
  }
}

.cardFormContainer {
  margin: rem(24) 0;

  .input {
    margin-top: rem(12);
  }

  .errorText {
    margin-top: rem(16);
    color: $errorRed;
    text-align: center;
    font-weight: bold;
    font-size: em(13);
  }

  .testMode {
    margin-top: rem(16);
    font-size: em(13);
    font-weight: bold;
    color: $errorRed;
  }

  .payBtn {
    margin-top: rem(48);
  }
}

.createAccountCardFormContainer {
  .errorText {
    margin-top: rem(16);
    color: $errorRed;
    text-align: center;
    font-weight: bold;
    font-size: em(13);
  }

  .testMode {
    font-size: em(13);
    font-weight: bold;
    color: $errorRed;
  }

  .payBtn {
    margin-top: rem(36);
  }
}

// Modals

.modalTitle {
  font-size: em(16);
  font-weight: bold;
  margin: rem(16) 0;
}

.modalActionBtns {
  flex-direction: row;
  width: 100%;
  margin-top: rem(32);

  & > button {
    flex: 1;
    margin: 0 rem(4);
    & > p {
      font-size: em(14);
    }
  }

  @include phone-only {
    flex-direction: column-reverse;

    & > button {
      min-height: rem(55);
      margin: rem(4) 0;
    }
  }

  .cancelBtn {
    background-color: $background-color;
    border: 1px solid $default-font-color;
    & > p {
      color: $default-font-color;
    }
  }

  .confirmCancelBtn {
    background-color: $errorRed;
  }
}
